<template>
  <b-container fluid >
    <b-row>
      <b-col md="12" class="heading_alignment">
        <iq-card style="padding-left:20px; padding-right:20px">
          <template v-slot:headerTitle>
            <h4 class="card-title">
              <i class="fa-solid fa-users-viewfinder" aria-hidden="true" style="font-size: 21px; margin-right: 10px; color: var(--iq-primary);"></i>
              {{cvCardTitle}}
            </h4>
          </template>
          <template>
            <b-row>
              <b-col sm="6" md="6" lg="6" xl="6" class="mb-2">
                <b-form-input
                  v-model="filter"
                  type="search"
                  :placeholder="cvSearchText"></b-form-input>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="container_minheight" md="12" v-if="followObjList && followObjList.length > 0">
                <b-table
                  hover
                  responsive
                  :items="followObjList"
                  :fields="columns"
                  :no-border-collapse="true"
                  :sort-by.sync="sortBy"
                  :sort-desc.sync="sortDesc"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  :current-page="currentPage"
                  :per-page="perPage">
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                    </template>
                  <template v-slot:cell(org_name)="data">
                    {{getLimitedText(data.item.org_name)}} <br/>
                    <i style="font-size:10px" title="Gide Univ Id">Univ Id: {{data.item.org_id}}</i> <br/>
                    <i class="fa fa-users m-0 pointer ml-2 font-size-20" title="Users" @click="goToUniv(data.item)"></i>
                  </template>
                </b-table>
              </b-col>
              <b-col class="container_minheight" md="12" v-else-if="!loadingFlag">
                No data found
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="7" md="8" class="my-1">
              </b-col>
              <b-col sm="5" md="4" class="my-1">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                  class="my-0">
              </b-pagination>
              </b-col>
            </b-row>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-toast v-model="showToast" name="Toast" :variant="toastVariant" :title="toastTitle">
      <div v-html="toastMsg">
      </div>
    </b-toast>
  </b-container>
</template>

<script>
import { analyticsFollower } from "../../../../FackApi/api/analytics/analytics_follower"
import { socialvue } from "../../../../config/pluginInit.js"
import userPermission from "../../../../Utils/user_permission.js"

export default {
  name: "analyticsFollowerCountReport",
  components: {
  },
  data () {
    return {
      apiName: "analytics_follower_count",
      cvCardTitle: "Follower Report ",
      cvSearchText: "Type to Search",
      showToast: false,
      toastMsg: null,
      toastVariant: "default",
      toastTitle: "Follower Response",
      sortBy: "name",
      sortDesc: false,
      columns: [],
      followObjList: null,
      filter: null,
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 50,
      loadingFlag: false
    }
  },
  computed: {
    userData () {
      return this.$store.getters["User/userData"]
    }
  },
  beforeMount () {
    // Auth Access for Current User Role
    if (!userPermission(this, this.userData, this.apiName)) {
      this.$router.back()
    }
  },
  async mounted () {
    socialvue.index()
    this.analyticsFollowerCount()
    let self = this

    switch (this.userData.user_role) {
      case "USERROLE11115": // Univ
      case "USERROLE11116": // Org
        self.columns = [
        // { label: "Action ", key: "action", class: "text-left align-text-top", sortable: false },
          { label: "# ", key: "index", class: "text-left align-text-top", sortable: true },
          { label: "Organisation Name", key: "org_name", class: "text-left align-text-top", sortable: true },
          { label: "Follower Count", key: "followerCount", class: "text-left align-text-top", sortable: true }
        ]
        break
      case "USERROLE11111": // What GIDE Admin Sees
      case "USERROLE11118":
        self.columns = [
          { label: "# ", key: "index", class: "text-left align-text-top", sortable: true },
          { label: "Organisation Name ", key: "org_name", class: "text-left align-text-top", sortable: true },
          { label: "Follower Count", key: "followerCount", class: "text-left align-text-top", sortable: true }
        ]
        break
      default:
        break
    }
  },
  methods: {
  /**
     * openUrlInNew
    */
    openUrlInNew (url) {
      window.open(url, "_blank")
    },
    /**
     * getLimitedText
     */
    getLimitedText (text) {
      if (text) {
        return text.substr(0, 100)
      }
      else {
        return text
      }
    },
    /**
     * analyticsFollowerCount
  */
    async analyticsFollowerCount () {
      let orgIdList = []
      if (this.userData?.modules?.organisations) {
        for (let orgId in this.userData.modules.organisations) {
          orgIdList.push(orgId)
        }
      }

      this.loadingFlag = true
      try {
        let OrgListResp = await analyticsFollower.analyticsFollowerCount(this, orgIdList)
        if (OrgListResp.resp_status) {
          this.followObjList = Object.values(OrgListResp.resp_data.data)
          this.totalRows = OrgListResp.resp_data.count
        }
        else {
          this.toastMsg = OrgListResp.resp_msg
          this.toastVariant = "danger"
          this.showToast = true
        }
      }
      catch (err) {
        console.error("Exception occurred at analyticsFollowerCount() and Exception:", err.message)
      }
      finally {
        this.loadingFlag = false
      }
    },
    /**
     * goToUniv
     */
    goToUniv (org) {
      this.$router.push(`/followerReport/${org.org_id}`)
    }
  }
}
</script>

<style lang="scss" scoped>
.dateRange {
  float: right;
}
.table thead tr th:first-child, .table tbody tr td:first-child{
  width: 100px !important;
}
.table{
  width: max-content;
}
</style>
